/*
 * Top Nav bar component
 */
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import user from '../assets/img/user.png'
import logo from '../assets/img/jedatis_logo_2.png';

const Navbar = (props) => {
    const history = useHistory();
    const {setLoggedIn,currentUser,logout,resetPassword,setMessage,setPreferredClinic,setNeedMedication,setVisitPurpose,setEmail,setBirthDate,setAddress,setPhone,setGender,setNric,setName,setPassword,setNationality}=useAuth();
    const currentEmail = currentUser ? currentUser.email : '';

    // Logout
    async function handleLogout(){
        if(window.confirm('Are you sure want to logout?')){
            try {
                // unset all the states
                setNric('');
                setName('');
                setGender('');
                setPhone('');
                setAddress('');
                setBirthDate('');
                setEmail('');
                setVisitPurpose('');
                setNeedMedication('');
                setPreferredClinic('');
                setMessage('');
                setLoggedIn(false);
                localStorage.setItem('LoggedIn',false)
                localStorage.setItem('cardAdded',false)
                localStorage.setItem('cardNo',null)
                localStorage.setItem('booked',false)
                await logout();
                toast.success('Logged out successfully');
                history.push('/');
                
            } catch (error) {
                toast.error('Failed to logout');
            }
        }
    }   
    
    // Change password
    async function changePassword(){
        if(window.confirm('Are you sure want to change password?')){
            try {
                await resetPassword(currentUser.email);
                toast.success('Password changed successfully ! Please check your inbox');
            } catch (error) {
                toast.error('Failed to lochange passwordgout');
            }
        }
    }

    // Redirect to dashboard via nav bar
    const goToDashboard =()=>{
        if(props.inqueue === "true"){
            if(window.confirm('Once you left the room, can not join back ! Are you sure want to leave?')){
                setMessage('');
                history.push('/dashboard');
                localStorage.setItem('booked',false)
            }
        }else{
            setMessage('');
            history.push('/dashboard');
        }
    }
    
    return (
        <nav className="navbar navbar-light navbar-expand-md" style={{background: '#378cc8',margin: '0',marginBottom: props.marginBtm}}>
        <div className="container-fluid" style={{flexWrap: 'nowrap'}}>
            <a className="navbar-brand" href="#" style={{flex:'10'}}>
                <img src={logo} width="10%" style={{minWidth: '100px'}}/>
            </a>
            {
                props.verified == 'true' && 
                <div class="collapse navbar-collapse" id="navbar-list-4" style={{justifyContent:'end'}}>
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={user} width="40" height="40" class="rounded-circle"/>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{right:'0',left:'auto'}}>
                    <a class="dropdown-item" style={{cursor:'pointer',fontWeight:'600'}}>{currentEmail}</a>
                    <hr style={{marginTop:'1px',marginBottom:'3px'}}/>
                    <a class="dropdown-item" style={{textDecoration:'none',cursor:'pointer',color:'#212529'}} onClick={goToDashboard}>Dashboard</a>
                    <a class="dropdown-item" style={{textDecoration:'none',cursor:'pointer',color:'#212529'}}> <Link style={{textDecoration:'none',cursor:'pointer',color:'#212529'}} to="/update/card">Change Card Details</Link></a>
                    <a class="dropdown-item" style={{cursor:'pointer'}} onClick={changePassword}>Change Password</a>
                    <a class="dropdown-item" style={{cursor:'pointer'}} onClick={handleLogout}>Log Out</a>
                    </div>
                </li>   
                </ul>
            </div>
            }
            
        </div>
     </nav>
    )
}

export default Navbar
