import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

var test_firebaseConfig = {
  apiKey: "AIzaSyAZheCLd3k9HmePN5o26xCe0qCvRp3jzRc",
  authDomain: "telemed-1.firebaseapp.com",
  databaseURL: "https://telemed-1.firebaseio.com",
  projectId: "telemed-1",
  storageBucket: "telemed-1.appspot.com",
  messagingSenderId: "53124580817",
  appId: "1:53124580817:web:f5c4556ceb48b064878cf0",
  measurementId: "G-XSSE5M7P98"
  };

var prod_firebaseConfig = {
    apiKey: "AIzaSyB2BGW57uQd0fRDmxI5gP4jPTLXUa9alNw",
    authDomain: "massive-amulet-285802.firebaseio.com",
    databaseURL: "https://massive-amulet-285802.firebaseio.com",
    projectId: "massive-amulet-285802",
    storageBucket: "massive-amulet-285802.appspot.com",
    messagingSenderId: "53124580817",
    appId: "1:1069652165054:android:56792da9c0fa50dfb6b9ef",
  };

const app = firebase.initializeApp(prod_firebaseConfig);

export default app;