/*
 * Update user component
 * Update user details before going to consultation
 */
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import moment from 'moment';
import { base_url } from '../RootNavigation';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

const UpdateUser = () => {
    const {nric,name,setName,gender,setGender,phone,setPhone,address,setAddress,birthDate,setBirthDate,email,setEmail,currentUser,visitPurpose,needMedication,preferredClinic,
        passport,setPassport,nationality,setNationality,device}=useAuth();
    const history=useHistory();
    const currentEmail = currentUser ? currentUser.email : '';

    const postAxios=(url, payload)=>{
        let header = {'Content-Type': 'application/json'};
        axios.post(`${url}`, payload, {headers: header}).then((response)=>{
            console.log("post success :", response.data['data']);
            var successful_booking = response.data['data']['joined_queue'];
            if (successful_booking) {
                toast.success('You have successfully joined the queue!');
                history.push('/waiting_room');
            } else {
                toast.error('Error booking consultation. Please try again')
            }
        }).catch((error)=>{
            var data = error.response.data['data'];
            toast.error(data['error']);
        });

      };
    
    //   Update user details
      const update=(e)=> {
          e.preventDefault();
          var pattern = new RegExp(/^[0-9\b]+$/);
        if (name == '' ) {
            toast.error('Name field cannot be empty')
        }else if (nric == '' || nric==undefined ) {
            toast.error('NRIC field cannot be empty')
        }else if (nric.length != 9 ) {
            toast.error('Invalid NRIC, must be 9 characters')
        }else if (gender != 'MALE' && gender != 'FEMALE') {
            toast.error('Gender field must either be MALE or FEMALE')
        }else if (phone.length != 8) {
            toast.error('Invalid Phone number, must be 8 digits')
        } 
        else if (!pattern.test(phone)) {
            toast.error('Invalid Phone number, must be in integers')
        }
         else if (address == '' ) {
            toast.error('Address field cannot be empty')
        } 
        else {
            var model = new FormData();

            model.displayName= currentUser.displayName;
            model.uid= currentUser.uid;
            model.email= currentUser.email;
            model.name= name;
            model.gender= gender;
            model.nric= nric;
            model.id_type= '';
            model.birthDate=moment(birthDate).format("DD/MM/YYYY");
            model.phone= phone;
            model.address= address;
            model.visitPurpose= visitPurpose;
            model.needMedication= needMedication;
            model.preferredClinic= preferredClinic;
            model.passport= passport;
            model.nationality= nationality;
            model.device = device;

            postAxios(base_url + '/patient/update', JSON.stringify(model))
        }
      }

    

    return (
        <>
       <Navbar verified="true" />
        <div className="container d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center justify-content-xl-center align-items-xl-center">
            <section className="login-clean" style={{background: 'rgb(255,255,255)',maxWidth: '720px',width: '80%',minWidth: '320px'}}>
                <form style={{background: '#d9f3fb',width: '100%',maxWidth: 'none'}}>
                    <div style={{marginBottom: '20px'}}>
                        <h3 className="text-center" style={{textAlign: 'center'}}>Patient Details</h3>
                    </div>
                    <div className="form-group"><label>Full Name</label>
                        <input className="form-control" type="text" placeholder="Full Name" name="name" value={name} required="" style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>NRIC/FIN</label>
                        <input className="form-control" type="text" placeholder="e.g. S1234567A" name="nric" value={nric} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Date of Birth</label>
                        <input className="form-control" type="date" name="dob" data-date-format="MM/DD/YYYY" 
                        value={birthDate} 
                        onChange={(e) => setBirthDate(e.target.value)} readOnly/>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                label="Date of birth"
                                inputFormat="dd/MM/yyyy"
                                value={birthDate}
                                onChange={(newValue) => {
                                    setBirthDate(newValue);
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center',borderBottom:'1px solid rgb(55, 140, 200)' }}>
                                    <input className="form-control" ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                </Box>
                                )}
                            />
                        </LocalizationProvider> */}
                    </div>
                    <div className="form-group"><label>Gender</label>
                        <select className="form-control" required="" 
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} 
                                name="reason"
                                onChange={(e) => setGender(e.target.value)}  
                                value={gender}      
                        >
                            <option value="" selected="">Select your gender</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                        </select>
                    </div>
                    <div className="form-group"><label>Mobile Number</label>
                        <input className="form-control" type="tel" name="mobile" placeholder="Mobile Number" value={phone} style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} required="" onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>Address</label>
                        <input className="form-control" type="text" placeholder="Address" name="address" value={address} required="" style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>Email</label>
                        <input className="form-control" type="email" name="email" placeholder="Email" value={currentEmail} style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} readOnly onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={update} style={{background: '#378CC8'}}>Register</button>
                    </div>
                </form>
            </section>
        </div>
        </>
    )
}

export default UpdateUser
