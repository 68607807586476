/*
 * Dasboard component - home page
 */
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { base_url } from '../RootNavigation';
import axios from 'axios';
import Navbar from '../components/Navbar';
import UpdateCard from './UpdateCard';
import card from '../assets/img/credit-card.svg'
import SupervisedArt from './SupervisedArt';
import {TextField} from "@material-ui/core";
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

const Dashboard = () => {
    const {currentUser,logout,nric,setNric,email,birthDate,setBirthDate,visitPurpose,setVisitPurpose,needMedication,
        setNeedMedication,preferredClinic,setPreferredClinic,message,cardAdded,setCardAdded,setMessage,setAddress,
        setPhone,setName,setGender}=useAuth();
    const history = useHistory();
    const today = moment(new Date()).format('YYYY-MM-DD');
    const[cardNo,setCardNo]=useState('');
    const[bookingSuccess,setBookingSuccess]=useState(false);
    
    // Book consultation
    async function bookConsultation(e){
        e.preventDefault();
        console.log(moment(birthDate).format("DD/MM/YYYY"))
        if (birthDate == undefined ||birthDate == '') {
            toast.error('Date of birth field cannot be empty')
        } 
        else if (nric == undefined ||nric == '') {
            toast.error('NRIC field cannot be empty')
        } 
        else if (nric.length != 9) {
            toast.error('Invalid NRIC, must be 9 characters')
        } 
        else if (visitPurpose == '' ) {
            toast.error('Please select a reason for visit')
        } else if (needMedication == '' ) {
            toast.error('Please select if you would like medication')
        } else if (preferredClinic == '' ) {
            toast.error('Please select your preferred clinic location')
        } else {
            if(visitPurpose == "Remotely Supervised Art for Travellers"){
                setBookingSuccess(true);
            }else{
                var model = new FormData();
                model.displayName= currentUser.displayName;
                model.uid= currentUser.uid;
                model.email= currentUser.email;
                model.nric= nric;
                model.id_type= '';
                model.birthDate=moment(birthDate).format("DD/MM/YYYY");
                model.visitPurpose= visitPurpose;
                model.needMedication= needMedication;
                model.preferredClinic= preferredClinic;

                let header = {'Content-Type': 'application/json'};
                let payload = JSON.stringify(model);
                let url = base_url + "/verify_patient";
                axios.post(url, payload, {headers: header})
                .then((response)=>{
                    var data = response.data['data'];
                    localStorage.setItem('booked',true);
                    if (data['patient_exists']) {
                        setName(data['name']);
                        setAddress(data['address']);
                        setPhone(data['phone']);
                        setGender(data['gender']);
                        var data = moment(data['birthDate'], "DD/MM/YYYY");
                        setBirthDate(data.format("YYYY-MM-DD"));
                        if(visitPurpose == "Remotely Supervised Art for Travellers"){
                            toast.success('Please add your details.');
                            history.push('/supervised-art')
                        }else{
                            toast.success('Please recheck your details.');
                            history.push('/user/update')
                        }
                        
                    } else {
                        toast.success('Please register the new patient details to book the consultation.');
                        history.push('/newuser/register/details')
                    }
                }).catch((error)=>{
                    if(error.response.status != 500 ){
                        var data = error.response.data['data'];
                    
                        if(data['is_valid_stripe']==false){
                            localStorage.setItem('booked',true);
                            toast.success('Please add your card details !');
                            history.push('/consultation/booking')
                        }
                        if(data['joined_queue']==false){
                            localStorage.setItem('booked',true);
                            toast.success('Please fill your details !');
                            history.push('/newuser/register/details')
                        }
                        if(data['patient_exists']==false){
                            toast.error(data['error']);
                        }
                    }
                });
            }
           
           
        }
    }

    // Check whether user's bill payment mode is added or not
    const cardCheck = ()=>{
        var model = new FormData();
        model.nric = nric;
        model.email=email;
        let header = {'Content-Type': 'application/json'}
        axios.post(base_url + '/pay/current', JSON.stringify(model), {headers: header}).then((response)=>{
            let success_status = response.status;
            if(success_status == 200){
                setCardAdded(true);
                localStorage.setItem('cardAdded',true);
                setCardNo(response.data);
                localStorage.setItem('cardNo',response.data);
            }
        }).catch((error)=>{
            var data = error.response.data['message'];
            let status = error.response.status;
            if(status == 404){
                setCardAdded(false);
                localStorage.setItem('cardAdded',false);
            }else{
                toast.error(data);
            }
            
        });
       
    }

    
    useEffect(() => {
        cardCheck();
    }, [])
 

    useEffect(() => {
        if(localStorage.getItem('cardAdded') == 'true'){
            setCardAdded(true);
        }else{
            setCardAdded(false);
        }
    }, [])

    useEffect(() => {
       setCardNo(cardNo)
    }, [cardNo])

    const cardSection =(cardAdded)=>{
        if(cardAdded){
            let cardDetails = "Card Ending with **** "+cardNo;
            return (
                <section className="d-inline login-clean" style={{background: 'rgb(255,255,255)',width: '100%'}}>
                <form method="post" style={{background: '#d9f3fb',width: '100%',maxWidth: 'none',height: '100%'}}>
                    <div style={{marginBottom: '20px'}}>
                        <h3 className="text-center" style={{textAlign: 'center'}}>Card Details</h3>
                    </div>
                    <div className="d-md-flex d-lg-flex justify-content-md-center justify-content-lg-center align-items-lg-center" style={{marginBottom: '20px'}}>
                        <img src={card} style={{maxWidth: '300px'}}/>
                    </div>
                    <div className="form-group">
                        <input className="form-control" type="text" name="number" required="" style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} 
                        inputmode="numeric" readOnly value={cardDetails} />
                    </div>
                </form>
            </section>
            );
        }else{
            return <UpdateCard/>;
        }

    }

    return (
        <>
            <Navbar verified="true" marginBtm="40px"/>
            <div className="container">
                {
                    message != '' &&
                    <div role="alert" class="fade-out alert alert-success show" >
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={() => setMessage('')}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {message}
                        
                    </div>
                }
            
               
                <div className="row" style={{display:"flex",marginBottom:"10px"}}>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{flex:"1",marginBottom:"5px"}}>
                    {
                        bookingSuccess ? 
                        <SupervisedArt/>
                        :
                        <section className="d-inline login-clean" style={{background: 'rgb(255,255,255)',width: '100%',paddingBottom: '40px'}}>
                        <form style={{background: '#d9f3fb',width: '100%',maxWidth: 'none',height: '100%'}}>
                            <div style={{marginBottom: '20px'}}>
                                <h3 className="text-center" style={{textAlign: 'center'}}>Booking Details</h3>
                            </div>
                            <div className="form-group" style={{display: 'flex',flexDirection: 'column'}}>
                                <label>Date of Birth</label>
                                {/* <input className="form-control" type="date" name="dob" max={today} required="" 
                                value={birthDate}
                                        style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                        
                                /> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                 {/* <DatePicker
                                        className="form-control"
                                        inputFormat="dd/MM/yyyy"
                                        disableFuture
                                        value={birthDate}
                                        onChange={(newValue) => setBirthDate(newValue)}
                                        renderInput={(props) => <TextField {...props} />}
                                        inputProps={{
                                            style: {
                                                background: '#f7f9fc',
                                                border: 'none',
                                                borderBottom: '1px solid rgb(55, 140, 200)',
                                                borderRadius: '0',
                                                boxShadow: 'none',
                                                outline: 'none',
                                                color: 'inherit',
                                                textIndent: '8px',
                                                height: '30px'
                                            },
                                          }}
                                    /> */}
                                     <DatePicker
                                        label="Date of birth"
                                        inputFormat="dd/MM/yyyy"
                                        value={birthDate}
                                        onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }}
                                        renderInput={({ inputRef, inputProps, InputProps }) => (
                                        <Box sx={{ display: 'flex', alignItems: 'center',borderBottom:'1px solid rgb(55, 140, 200)' }}>
                                            <input className="form-control" ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                        </Box>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="form-group"><label>NRIC/FIN</label>
                                <input className="form-control" type="text" placeholder="e.g. S1234567A" name="nric"
                                value={nric} required="" 
                                        style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}}
                                        onChange={(e) => setNric(e.target.value)}     
                                />
                            </div>
                            <div className="form-group"><label>Reason for Visit</label>
                                <select className="form-control" required="" 
                                        style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} 
                                        name="reason"
                                        onChange={(e) => setVisitPurpose(e.target.value)}       
                                >
                                    <option value="" selected="">Select a reason for visit</option>
                                    <option value="Refill medicine">Refill medicine</option>
                                    <option value="Sickness consultation">Sickness consultation</option>
                                    <option value="Admin matters">Admin matters</option>
                                    <option value="Remotely Supervised Art for Travellers">Remotely Supervised Art for Travellers</option>
                                </select>
                            </div>
                            <div className="form-group"><label>Choice of Medication</label>
                                <select className="form-control" required="" 
                                        style={{borderColor: 'transparent',borderBottomColor:' rgb(55, 140, 200)'}} 
                                        name="choice"
                                        onChange={(e) => setNeedMedication(e.target.value)}    
                                >
                                    <option value="" selected="">Select if you would like medication</option>
                                    <option value="YES" selected="">Yes, I agree to take all medications prescribed by the doctor</option>
                                    <option value="NO">No, I only need consultation, I have my own medication</option>
                                </select>
                            </div>
                            <div className="form-group"><label>Preferred Clinic Location</label>
                                 <select className="form-control" required="" 
                                        style={{borderColor: 'transparent',borderBottomColor:' rgb(55, 140, 200)'}} 
                                        name="clinic"
                                        onChange={(e) => setPreferredClinic(e.target.value)}    
                                >
                                    <option value="" selected="">Select your preferred clinic location</option>
                                    <option value="Bedok" >Bedok</option>
                                    <option value="Jurong West">Jurong West</option>
                                    <option value="Marine Terrace" >Marine Terrace</option>
                                    <option value="Rivervale">Rivervale</option>
                                    <option value="Simei" >Simei</option>
                                    <option value="Tampines">Tampines</option>
                                    <option value="Toa Payoh" >Toa Payoh</option>
                                    <option value="Yishun">Yishun</option>
                                    <option value="Bedok">Bedok</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block" onClick={bookConsultation} style={{background: '#378CC8'}}>Book Consultation</button>
                            </div>
                        </form>
                    </section>
                    }
                    </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"style={{flex:"1",marginBottom:"5px"}}>
                            {
                                cardSection(cardAdded)
                            }
                        </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
