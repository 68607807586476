/*
 * Dasboard component - home page
 */
import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { base_url } from '../RootNavigation';
import axios from 'axios';
import Navbar from '../components/Navbar';
import UpdateCard from './UpdateCard';
import card from '../assets/img/credit-card.svg'
import validator from 'validator'

const SupervisedArt = () => {
    const {currentUser,logout,nric,setNric,email,birthDate,setBirthDate,visitPurpose,setVisitPurpose,needMedication,
        setNeedMedication,preferredClinic,setPreferredClinic,message,cardAdded,setCardAdded,setMessage,setAddress,
        setPhone,setName,setGender,passport,setPassport,nationality,setNationality}=useAuth();
    const history = useHistory();
    const nationalities = [
        {value:'IN',label:'Indian'},
        {value:'US',label:'American'},
        {value:'LK',label:'Sri Lankan'},
        {value:'UK',label:'British'},
    ]


    // Book consultation
    async function bookConsultation(e){
        e.preventDefault();
        if (nationality == undefined ||nationality == '') {
            toast.error('Nationality field cannot be empty')
        }
        else if (passport== undefined ||passport == '') {
            toast.error('Passport field cannot be empty')
        } 
        else if(!validator.isPassportNumber(passport,nationality)){
            toast.error('Invalid Passport Number')
        }else {
            var model = new FormData();
            model.displayName= currentUser.displayName;
            model.uid= currentUser.uid;
            model.email= currentUser.email;
            model.nric= nric;
            model.id_type= '';
            model.birthDate=moment(birthDate).format("DD/MM/YYYY");
            model.visitPurpose= visitPurpose;
            model.needMedication= needMedication;
            model.preferredClinic= preferredClinic;
            model.passport= passport;
            model.nationality= nationality;

            let header = {'Content-Type': 'application/json'};
            let payload = JSON.stringify(model);
            let url = base_url + "/verify_patient";
            axios.post(url, payload, {headers: header})
            .then((response)=>{
                var data = response.data['data'];
                localStorage.setItem('booked',true);
                if (data['patient_exists']) {
                    setName(data['name']);
                    setAddress(data['address']);
                    setPhone(data['phone']);
                    setGender(data['gender']);
                    var data = moment(data['birthDate'], "DD/MM/YYYY");
                    setBirthDate(data.format("YYYY-MM-DD"));
                    toast.success('Please recheck your details.');
                    history.push('/user/update')
                } else {
                    toast.success('Please register the new patient details to book the consultation.');
                    history.push('/newuser/register/details')
                }
            }).catch((error)=>{
                if(error.response.status != 500 ){
                    var data = error.response.data['data'];
                   
                    if(data['is_valid_stripe']==false){
                        localStorage.setItem('booked',true);
                        toast.success('Please add your card details !');
                        history.push('/consultation/booking')
                    }
                    if(data['joined_queue']==false){
                        localStorage.setItem('booked',true);
                        toast.success('Please fill your details !');
                        history.push('/newuser/register/details')
                    }
                    if(data['patient_exists']==false){
                        toast.error(data['error']);
                    }
                }
            });
        }
    }
    return (
        <>
                
                        <section className="d-inline login-clean" style={{background: 'rgb(255,255,255)',width: '50%'}}>
                            <form style={{background: '#d9f3fb',width: '100%',maxWidth: 'none',height: '100%'}}>
                                <div style={{marginBottom: '20px'}}>
                                    <h3 className="text-center" style={{textAlign: 'center'}}>Details of Supervised Art</h3>
                                </div>
                               <div style={{marginTop:'40px'}}>
                               <div className="form-group"><label>Nationality</label>
                                    {/* <input className="form-control" type="text"  name="nationality"
                                    value={nationality} required="" 
                                    style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}}
                                    onChange={(e) => setNationality(e.target.value)}     
                                    /> */}

                                <select className="form-control" required="" 
                                        style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} 
                                        name="nationality"
                                        onChange={(e) => setNationality(e.target.value)}       
                                >
                                    <option value="" selected="">Select Nationailty</option>
                                    {
                                        nationalities.map(dt=>
                                            <option value={dt.value}>{dt.label}</option>
                                        )
                                    }
                                   
                                </select>
                                </div>
                                <div className="form-group"><label>Passport Number</label>
                                    <input className="form-control" type="text"  name="passport"
                                    value={passport} required="" 
                                    style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}}
                                    onChange={(e) => setPassport(e.target.value)}     
                                    />
                                </div>
                               
                               
                               
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block" onClick={bookConsultation} style={{background: '#378CC8'}}>Book Consultation</button>
                                </div>
                                </div>
                            </form>
                        </section>
                      
        </>
    )
}

export default SupervisedArt
