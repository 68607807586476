/*
 * Register component
 */
import React, { useState } from 'react'
import app from '../firebase'
import { useAuth } from '../contexts/AuthContext';
import { Link,useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../assets/css/style.css'

const Register = (props) => {
    const[password,setPassword]=useState('');
    const[displayName,setDisplayName]=useState('');
    const[agreed,setAgreed]=useState(0);

    const {register,email,setEmail} = useAuth();
    const history=useHistory();

    // Register user
    async function handleSubmit (e){
        e.preventDefault();
        if(email === '' && displayName === '') {
            toast.error('Enter details to signup!')
        } else if (password.length < 6) {
            toast.error('Password must be more than 6 characters')
        }else if(agreed == 0){
            toast.error('Please agree to the licence terms to register')
        } else {
            try {
                await register(email,password).then((res) => {
                    localStorage.setItem('LoggedIn',false)
                    res.user.updateProfile({displayName: displayName})
                    app.auth().currentUser.sendEmailVerification().then(function() {
                      toast.success('Thanks for signing up! Please verify the link sent to your email , One more step , please fill your details')
                      history.push('/newuser/register/details');
                    }).catch(function(error) {
                      toast.error(error.errorMessage)
                    });
                  }).catch(function(error) {
                    localStorage.setItem('LoggedIn',false)
                      var error_msg = "Sorry this email has already been taken."
                      toast.error(error_msg)
                    })
            } catch (error) {
                localStorage.setItem('LoggedIn',false)
                toast.error('Failed to create an account')
            }
        }
    }


    return (
        <div class="d-flex d-md-flex justify-content-center align-items-center justify-content-md-center align-items-md-center" style={{color: "var(--indigo)",background: "rgb(255,255,255)"}}>
        <section className="login-clean" style={{background: 'rgb(255,255,255)'}}>
            <form style={{background: '#d9f3fb',maxWidth: '540px'}}>
                <div className="illustration">
                    <img src="assets/img/jedatis_logo_2.png" width="80%"/></div>
                <div style={{marginBottom: '20px'}}>
                    <h3 className="text-center" style={{textAlign: 'center'}}>Create Account</h3>
                </div>
                <div className="form-group">
                    <input className="form-control" type="email" name="email" placeholder="Email" style={{borderColor:'#378CC8'}} required="" onChange={(e)=>setEmail(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input className="form-control" type="text" placeholder="Name" name="name" required="" style={{borderColor: '#378CC8',borderBottomColor:'rgb(55, 140, 200)'}} onChange={(e)=>setDisplayName(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input className="form-control" type="password" placeholder="Password" name="password" required="" style={{borderBottomColor: 'rgb(55,140,200)'}} onChange={(e)=>setPassword(e.target.value)}/>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" required="" onChange={()=>setAgreed(!agreed)}/>I agree to the license 
                        <a style={{fontWeight:"600"}} href="/terms_conditions" target="_blank"> Terms & Conditions</a>
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <button className="btn btn-primary btn-block" onClick={handleSubmit} style={{background: '#378CC8'}} >Register</button>
                </div>
                <span className="text-center forgot" href="#" style={{textAlign: 'center'}}><Link to="/">Click Here to Login</Link></span>
            </form>
        </section>
        
        </div>
    )
}

export default Register

