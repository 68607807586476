/*
 * Parent component of Card component
 */

import Card from './Card';
import Navbar from '../components/Navbar';

const Booking = () => {
    return (
        <>
      <Navbar verified="true" marginBtm="40px"/>
        <div className="container">
            <div className="row d-md-flex align-items-center justify-content-center">
                   <Card/>
            </div>
        </div>
        </>
    )
}

export default Booking
