/*
 * Card component
 * add card details
 */
import React, { useState } from 'react'
import card from '../assets/img/credit-card.svg'
import { base_url } from '../RootNavigation';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

// Stripe keys
// const STRIPE_PUBLISHABLE_KEY = 'pk_test_TLEj2HsqjO2XxtKU1d6vH3Ln00bIm1Ws6j';
// const STRIPE_SECRET_KEY = 'sk_test_zZjbO8B5H4IvobENCRFEUnAV00WBLD7nx7';
// var stripe = require('stripe-client')(STRIPE_PUBLISHABLE_KEY);

const Card = () => {
    const history=useHistory();
    const {currentUser,setMessage,setCardAdded,email}=useAuth();

    // card details
    const[cardName,setCardName]=useState('');
    const[cardNumber,setCardNumber]=useState('');
    const[expiry,setExpiry]=useState('');
    const[month,setMonth]=useState('');
    const[year,setYear] = useState('');
    const[cvc,setCvc]=useState('');

    // send card details to server
    function setupPayment(e) {
        e.preventDefault();
        if(cvc == ''){
            toast.error('CVC cannot be empty')
        }else{
            var model = new FormData();
            model.name = cardName;
            model.number = cardNumber;
            model.cvc = cvc;
            model.exp_month = month;
            model.exp_year = year;
            model.email=email;
            postAxios(base_url + '/pay/setup', JSON.stringify(model))
        }
        
      };

      const postAxios=(url, payload)=>{
        let header = {'Content-Type': 'application/json'}
        axios.post(`${url}`, payload, {headers: header}).then((response)=>{
            var valid_card = response.data['data']['valid_card'];
            if (valid_card == true) {
              
              setMessage('');
              setCardAdded(true);
              localStorage.setItem('cardAdded',true);
              let booked = localStorage.getItem('booked');
              if(booked == 'true'){
                toast.success('Payment successfully updated ! Please recheck your details.');
                history.push('/user/update')
              }else{
                toast.success('Payment successfully updated ! Start booking');
                history.push('/dashboard')
              }
            } else {
              var error_message = response.data['message'];
              toast.error(error_message)
            }
        }).catch((error)=>{
            var data = error.response.data['message'];
            toast.error(data);
        });
       
      };

    // Send payment request to stripe server
    async function makePayment() {
        var url = "http://127.0.0.1:5000/pay/";
        let payload = JSON.stringify({
            amount: '123',
            customer_id: 'john smith',
            payment_method: 'credit card'
        })
        postAxios(url, payload)
    }


    return (
        <section className="d-inline login-clean billing" style={{background: 'rgb(255,255,255)',width: '50%'}}>
                        <form style={{background: '#d9f3fb',width: '100%',maxWidth: 'none'}}>
                            <div style={{marginBottom: '20px'}}>
                                <h3 className="text-center" style={{textAlign: 'center'}}>Billing Details</h3>
                            </div>
                            <div className="d-md-flex d-lg-flex justify-content-md-center justify-content-lg-center align-items-lg-center" style={{marginBottom: '20px'}}>
                                <img src={card} style={{maxWidth: '200px'}}/></div>
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Name on the Card" name="name" required="" 
                                onChange={(e)=>{setCardName(e.target.value)}}
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}}/>
                            </div>
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="16 Digit Card Number" name="card_number" required="" 
                                onChange={(e)=>{setCardNumber(e.target.value)}}
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} inputmode="numeric"/>
                            </div>
                            <div className="form-group d-flex justify-content-xl-center">
                                <input className="form-control d-xl-flex" type="text" placeholder="MM" name="month_year" required="" 
                                onChange={(e)=>{setMonth(e.target.value)}}
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)',marginRight: '5px'}}/>
                                <input className="form-control d-xl-flex" type="text" placeholder="YY" name="month_year" required="" 
                                onChange={(e)=>{setYear(e.target.value)}}
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)',marginRight: '5px'}}/>
                                <input className="form-control" type="text" placeholder="CVC" name="cvc" required="" 
                                onChange={(e)=>{setCvc(e.target.value)}}
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)',marginLeft: '5px'}} inputmode="numeric"/></div>
                            <div className="form-group" style={{marginBottom: '20px'}}>
                                <button className="btn btn-primary btn-block" onClick={setupPayment} style={{background: '#378CC8'}}>Add New Card</button>
                            </div>
                        </form>
                    </section>
    )
}

export default Card
