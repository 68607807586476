import { AuthProvider } from "./contexts/AuthContext";
import {BrowserRouter as Router, Switch,Route,Redirect} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Booking from "./pages/Booking";
import Dashboard from "./pages/Dashboard";
import FirstTimeUser from "./pages/FirstTimeUser";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Waiting from "./pages/Waiting";
import UpdateUser from "./pages/UpdateUser";
import TermsCondition from "./pages/TermsCondition";
import UpdateCardSection from "./pages/UpdateCardSection";
import SupervisedArt from "./pages/SupervisedArt";

function App() {

  return (
     
        <div className="App">
          <ToastContainer 
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Router>
            <AuthProvider>
                <Switch>
                  <Route exact path="/" component={Login}/>
                  <Route path="/register" component={Register}/>
                  <Route path="/terms_conditions" component={TermsCondition}/>
                  <PrivateRoute path="/dashboard" component={Dashboard}/>
                  <PrivateRoute path="/supervised-art" component={SupervisedArt}/>
                  <Route path="/newuser/register/details" component={FirstTimeUser}/>
                  <PrivateRoute path="/consultation/booking" component={Booking}/>
                  <PrivateRoute path="/update/card" component={UpdateCardSection}/>
                  <PrivateRoute path="/user/update" component={UpdateUser}/>
                  <PrivateRoute path="/waiting_room" component={Waiting}/>
                </Switch>
            </AuthProvider>
          </Router>
        </div>
     
  );
}

export default App;
