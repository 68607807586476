import React from 'react'
import { Route,Redirect } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'

const PrivateRoute = ({component:Component,...rest}) => {
    const loggedIn = localStorage.getItem('LoggedIn');
  
    console.log(loggedIn);
    return (
        <Route {...rest}
            render={props=>{
                return loggedIn=='true' ? <Component {...props}/> : <Redirect to="/"/>
            }}
        >
            
        </Route>
    )
}

export default PrivateRoute
