/*
 * Waiting component
 * patient will be queued here and upon on doc availability will be taken into video consultation
 */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import { base_url } from '../RootNavigation';
import Navbar from '../components/Navbar';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import './waitingRoom.css'


// const tapiKey = '46878494'
// const testSessionId = '2_MX40NjcwMDk3Mn5-MTU4OTYwNTY0NTQ5NX5Gd0VoVjgyV0JmdjBEQkNwR2NxaFB6bnB-UH4'
// const testToken = 'T1==cGFydG5lcl9pZD00NjcwMDk3MiZzaWc9NjJhMTVhY2EwY2MzZjFiZjc0NDU4MTkxYjBmYzIxZDUyZmFjMmEwNTpzZXNzaW9uX2lkPTJfTVg0ME5qY3dNRGszTW41LU1UVTRPVFl3TlRZME5UUTVOWDVHZDBWb1ZqZ3lWMEptZGpCRVFrTndSMk54YUZCNmJuQi1VSDQmY3JlYXRlX3RpbWU9MTU4OTYwNTY0NSZleHBpcmVfdGltZT0xNTg5NjkyMDQ1JnJvbGU9cHVibGlzaGVyJm5vbmNlPTIyOTg0MiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=='

const Waiting = () => {
    const history=useHistory();
    const {currentUser,nric,setMessage,device}=useAuth();

    const[apiKey,setApiKey]=useState("");
    const[token,setToken]=useState("");
    const[sessionId,setSessionId]=useState("");
    var _isMounted=false;
    var callback=false;
   
    // Cancel Consultation
    function cancelConsultation(){
        if(window.confirm('Are you really sure you want to cancel the booking')){
            const model = new FormData();
            model.displayName= currentUser.displayName;
            model.uid= currentUser.uid;
            model.email= currentUser.email;
            model.nric= nric;
            model.apiKey= apiKey;
            model.sessionId= sessionId;
            model.token= token;
            model.timePassed= false;
            model.callback= callback;
            model.description= '';
            model.device = device;

            postAxios(base_url + "/cancel_booking", JSON.stringify(model))
        }
    }

      // Reschedule consultation
      function rescheduleConsultation (){
        if(window.confirm('Are you sure you want to reschedule the booking and have have our next available doctor to call you back?')){
            callback=true;
            const model = new FormData();
            model.displayName= currentUser.displayName;
            model.uid= currentUser.uid;
            model.email= currentUser.email;
            model.nric= nric;
            model.apiKey= apiKey;
            model.sessionId= sessionId;
            model.token= token;
            model.timePassed= false;
            model.callback= callback;
            model.description= '';
            postAxios(base_url + "/cancel_booking", JSON.stringify(model))
        }
    };

    const postAxios=(url, payload)=>{
        let header = {'Content-Type': 'application/json'}
        axios.post(`${url}`, payload, {headers: header}).then((response)=>{
            var data = response.data['data'];
    
            if (data['callback'] == true) {
                toast.success("Rescheduled !");
                setMessage("Rescheduled,our doctor will contact you as soon as possible. We will contact you via sms or email. Please follow the instructions in the message. The doctor will specify the date and time for the consultation. If you are unavailable at the allocated time, please queue again.");
                history.push('/dashboard')
                localStorage.setItem('booked',false)
            } else if ("call_ended" in data) {
                toast.success("Completed !");
                setMessage("Completed,Your consultation has ended. Please check your email in a while for the receipt and any medical instructions.");
                history.push('/dashboard')
                localStorage.setItem('booked',false)
            } else if ("sessionId" in data) {
                setApiKey(data['apiKey']);
                setSessionId(data['sessionId']);
                setToken(data['token']);
            } else if (data['booking_cancelled'] == true) {
                toast.success("Consultation Cancelled !");
                setMessage("Consultation Cancelled , Your consultation has ended. Thank you, hope to see you next time.")
                history.push('/dashboard')
                localStorage.setItem('booked',false)
            }
        }).catch((error)=>{
            toast.error(error);
            return null
        });
      };

  

// Check doctor status
  async function checkDoctorReady() {
      if ( _isMounted == true ) {
        const model = new FormData();
        model.displayName= currentUser.displayName;
        model.uid= currentUser.uid;
        model.email= currentUser.email;
        model.nric= nric;
        model.apiKey= apiKey;
        model.sessionId= sessionId;
        model.token= token;
        model.timePassed= false;
        model.callback= callback;
        model.description= '';
       
       
        postAxios(base_url + "/start_patient_video", JSON.stringify(model));
      }
  }

  useEffect(() => {
    _isMounted = true;
    // if (sessionId == "") {
        const interval = setInterval(() => {
            checkDoctorReady()
        }, 1000);
        return () => {
            clearInterval(interval);
            _isMounted = false;
        }
    // }
  }, [])

        if(sessionId != "" && token != ""){
            return (
                <>
                {/* <Navbar verified="true" inqueue="true"/> */}
                <OTSession apiKey={apiKey} sessionId={sessionId} token={token} onError={(err)=>console.log(err.message)}>
                    <div className="waiting_container">
                        <div className='videoContainer'>
                            <div className='pubisherVideo'>   
                                <OTPublisher properties={{height:'100%',width:'100%'}} showControls={true} />             
                            </div>
                            <div className='subscriberVideo'>
                                <OTStreams>
                                    <OTSubscriber 
                                        properties={{height:'100%',width:'100%'}} 
                                        showControls={true} 
                                    />
                                </OTStreams>
                            </div>
                        </div>
                    </div>
                 </OTSession>
                 </>
                )
         }else{
            return(
                <>
                <Navbar verified="true" inqueue="true" marginBtm="40px"/>
                <div className="container d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center justify-content-xl-center align-items-xl-center">
                    <div className="row">
                        <div className="col" style={{borderColor: 'transparent'}}>
                            <div className="card d-xl-flex" style={{maxWidth: '720px',background: '#D9F3FB'}}>
                                <div className="card-body">
                                    <h2 className="card-title" style={{textAlign: 'center'}}>Waiting Room</h2>
                                    <div className="d-flex d-xl-flex justify-content-center justify-content-xl-center" style={{margin: '25px 0px'}}>
                                        <img src="assets/img/clipboard-image.png" width="20%"/>
                                    </div>
                                    <p className="card-text" style={{fontSize: '16px'}}>
                                        Connecting you to the next available doctor, please wait 1-15 mins ...
                                        <br/><br/>If you are unable to wait, would you like our doctor to reschedule an appointment with you?
                                    </p>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-xl-flex justify-content-xl-center align-items-xl-center" style={{borderColor: 'transparent',marginBottom: '10px'}}>
                                            <button className="btn btn-primary btn-block" type="button"onClick={rescheduleConsultation} style={{background: '#0d9b5b'}}>Yes, Reschedule Consultation</button></div>
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-xl-flex justify-content-xl-center align-items-xl-center"  style={{borderColor: 'transparent',marginBottom: '10px'}}>
                                            <button className="btn btn-primary btn-block" type="button" onClick={cancelConsultation} style={{background: '#9b400c'}}>No, Cancel consultation</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                </>
            )
        }
    }
export default Waiting
