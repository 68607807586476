/*
 * Componet to register new users details
 */
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import moment from 'moment';
import { base_url } from '../RootNavigation';
import axios from 'axios';

import { useHistory } from 'react-router';
import { useAuth } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';

const FirstTimeUser = () => {

    const {setCurrentUser,logout,nric,setNric,name,setName,gender,setGender,phone,setPhone,address,setAddress,birthDate,setBirthDate,
        email,setEmail,currentUser,nricFinalize,loggedIn,setFirstUser,visitPurpose,needMedication,preferredClinic,
        passport,setPassport,nationality,setNationality,device}=useAuth();
    const currentEmail = currentUser ? currentUser.email : '';
    const history=useHistory();
    
    let uLoggedIn = localStorage.getItem('LoggedIn');

    const postAxios=(url, payload)=>{
       
        let header = {'Content-Type': 'application/json'};
        axios.post(`${url}`, payload, {headers: header}).then((response)=>{
            localStorage.setItem('reg',true);
            nricFinalize(nric);
            var data = response.data['data'];
            console.log("post success :", data);
            if (data['is_new_user']) {
                if(uLoggedIn == 'true'){
                    toast.success('Thanks for filling your details! Please enter the payment details.');
                    history.push('/consultation/booking')
                }else{
                    toast.success('Thanks for filling your details!');
                    history.push('/')
                }
            } else if (data['joined_queue']) {
                toast.success('You have successfully joined the queue!');
                history.push('/waiting_room')
            } else {
                toast.error('Error booking consultation. Please try again')
            }
           
        }).catch((error)=>{
            
            var data = error.response.data['data'];
            console.log(data);
            toast.error(data['Error']);
        });
       
      };
    
    //   Register user
      const register=(e)=> {
          e.preventDefault();
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (name == '' ) {
            toast.error('Name field cannot be empty')
        } else if (nric == '' || nric==undefined ) {
            toast.error('NRIC field cannot be empty')
        }  else if (nric.length != 9 ) {
            toast.error('Invalid NRIC, must be 9 characters')
        } 
        else if (gender != 'MALE' && gender != 'FEMALE') {
            toast.error('Gender field must either be MALE or FEMALE')
        } 
        else if (phone.length != 8) {
            toast.error('Invalid Phone number, must be 8 digits')
        } 
        else if (!pattern.test(phone)) {
            toast.error('Invalid Phone number, must be in integers')
        } 
         else if (address == '' ) {
            toast.error('Address field cannot be empty')
        } 
        // else if (moment(birthDate, "DD/MM/YYYY", true).isValid() == false) {
        //     toast.error('Birthday field must be valid date and with format DD/MM/YYYY')
        // } 
        else {
            var model = new FormData();

            model.displayName= currentUser.displayName;
            model.uid= currentUser.uid;
            model.email= email;
            model.name= name;
            model.gender= gender;
            model.nric= nric;
            model.id_type= '';
            model.birthDate=moment(birthDate).format("DD/MM/YYYY");
            model.phone= phone;
            model.address= address;
            model.visitPurpose= visitPurpose;
            model.needMedication= needMedication;
            model.preferredClinic= preferredClinic;
            model.passport= passport;
            model.nationality= nationality;
            model.device=device;

            nricFinalize(nric);
            postAxios(base_url + '/patient/register', JSON.stringify(model))
        }
      }

      const today = moment(new Date()).format('YYYY-MM-DD');

    //   Cancel registration
      const cancel = ()=>{
        logout();
        setCurrentUser();
        localStorage.setItem('reg',false)
        history.push('/');
      }
    

    return (
        <>
        <Navbar verified={uLoggedIn}/>
        <div className="container d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-center align-items-center justify-content-sm-center align-items-sm-center justify-content-md-center align-items-md-center justify-content-lg-center align-items-lg-center justify-content-xl-center align-items-xl-center">
            <section className="login-clean" style={{background: 'rgb(255,255,255)',maxWidth: '720px',width: '80%',minWidth: '320px'}}>
                <form method="post" style={{background: '#d9f3fb',width: '100%',maxWidth: 'none'}}>
                    <div style={{marginBottom: '20px'}}>
                        <h3 className="text-center" style={{textAlign: 'center'}}>Patient Details</h3>
                    </div>
                    <div className="form-group"><label>Full Name</label>
                        <input className="form-control" type="text" placeholder="Full Name" name="name" required="" style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>NRIC/FIN</label>
                        <input className="form-control" type="text" placeholder="e.g. S1234567A" name="nric" required="" 
                        value={nric}
                        style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setNric(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <label>Date of Birth</label>
                        {/* <input className="form-control" type="date" name="dob" data-date-format="dd/mm/yyyy" max={today} 
                        value={birthDate} 
                        required=""
                        style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setBirthDate(e.target.value)}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                label="Date of birth"
                                inputFormat="dd/MM/yyyy"
                                value={birthDate}
                                onChange={(newValue) => {
                                    setBirthDate(newValue);
                                }}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                <Box sx={{ display: 'flex', alignItems: 'center',borderBottom:'1px solid rgb(55, 140, 200)' }}>
                                    <input className="form-control" ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="form-group"><label>Gender</label>
                        <select className="form-control" required="" 
                                style={{borderColor: 'transparent',borderBottomColor: 'rgb(55, 140, 200)'}} 
                                name="reason"
                                onChange={(e) => setGender(e.target.value)}       
                        >
                            <option value="" selected="">Select your gender</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                        </select>
                    </div>
                    <div className="form-group"><label>Mobile Number</label>
                        <input className="form-control" type="tel" name="mobile" placeholder="Mobile Number" style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} required="" onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>Address</label>
                        <input className="form-control" type="text" placeholder="Address" name="address" required="" style={{borderColor: '#378CC8',borderBottomColor: 'rgb(55, 140, 200)'}} onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                    <div className="form-group"><label>Email</label>
                        <input className="form-control" type="email" name="email" placeholder="Email" style={{backgroundColor: '#f7f9fc'}} required="" value={currentEmail} readOnly onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={register} style={{background: '#378CC8'}}>Register</button>
                    </div>
                    {
                        uLoggedIn == 'false' &&<div className="form-group"  style={{textAlign: 'center'}}>
                        <a style={{fontWeight: '500',cursor:"pointer"}} className="text-danger" onClick={cancel}>Cancel</a>
                    </div>
                    }
                    
                </form>
            </section>
        </div>
        </>
    )
}

export default FirstTimeUser
