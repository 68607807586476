// RootNavigation.js

import * as React from 'react';

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

// base url for flask app
export const base_url = "https://tm.jedatis.com";
//export const base_url = "https://3297f441dfdb.ngrok.io";
// export const base_url = "http://35.240.229.111";
//export const local_url = "http://127.0.0.1:9090";
// export const base_url = "https://48cd-2401-7400-c806-ea5-c11f-ee32-b61a-8ca6.ngrok.io";

