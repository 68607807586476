/*
 * Parent Component of UpdateCard component
 */
import Navbar from '../components/Navbar';
import UpdateCard from './UpdateCard';

const UpdateCardSection = () => {
    
    return (
        <>
          <Navbar verified="true"/>
          <div className="container">
            <div className="row d-md-flex align-items-center justify-content-center">
                   <UpdateCard/>
            </div>
        </div>
        </>
    )
}

export default UpdateCardSection
