/*
 * Login component
 * consits of login form & reset password modal
 */
import React, { useState,useEffect } from 'react'
import { Link,useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import app from '../firebase';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import {isMobile} from 'react-device-detect';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding:'0',
      border:'none'
    },
    overlay: {
        background: "rgb(211,211,211,0.9)"
      }
   
  };

const Login = (props) => {

    const[lemail,setLemail]=useState('');
    const[password,setPassword]=useState('');
    const {login,resetPassword,currentUser,setCurrentUser,setMessage,setLoggedIn,firstUser,setEmail,device,setDevice} = useAuth();
    const history=useHistory();

    useEffect(() => {
        if(isMobile){
            setDevice('mobile')
        }else{
            setDevice('web')
        }
      }, [])

     function handleSubmit (e){
        e.preventDefault();
        if(lemail === '' && password === '') {
            toast.error('Enter details to log in !')
        } else {
                login(lemail,password).then(()=>{
                    var user = app.auth().currentUser;
                    if(user.emailVerified){
                        setCurrentUser(user);
                        setMessage('');
                        setEmail(user.email);
                        localStorage.setItem('LoggedIn',true)
                        let skippedReg = localStorage.getItem('reg')
                        if(skippedReg == 'false'){
                            history.push('/newuser/register/details');
                        }else{
                            history.push('/dashboard');
                        }
                        toast.success('Welcome')
                    }else{
                        toast.error('Please verify your account !')
                    }
                }).catch (error => toast.error(error.message))
           
        }
    }
  const [modalIsOpen, setIsOpen] = React.useState(false);

    //   Open reset password modal
  function openModal() {
    setIsOpen(true);
  }
    //   Close reset password modal
  function closeModal() {
    setIsOpen(false);
  }

  //  Send reset mail
  async function sendResetMail(e){
    e.preventDefault();
    if(lemail === '') {
        toast.error('Please enter your email to reset password!')
    }else {
        try {
            await resetPassword(lemail);
            setIsOpen(false);
            toast.success("A password reset link has been sent to the email address provided");
        } catch (error) {
            toast.error("Failed to reset password");
        }
    }
    
  }
  

    return (
        <div class="d-flex d-md-flex justify-content-center align-items-center justify-content-md-center align-items-md-center" style={{color: "var(--indigo)",background: "rgb(255,255,255)"}}>
        <section className="login-clean" style={{background: 'rgb(255,255,255)'}}>
            <form style={{background: '#d9f3fb',maxWidth: '540px'}}>
            <div className="illustration">
                <img src="assets/img/jedatis_logo_2.png" width="80%"/>
            </div>
            <div style={{marginBottom: '20px'}}>
                <h3 className="text-center" style={{textAlign: 'center',color: 'rgb(80, 94, 108)'}}>Login</h3>
            </div>
            <div className="form-group">
                <input className="form-control" type="email" name="email" placeholder="Email" style={{borderColor: '#378CC8'}} required="" onChange={(e)=>setLemail(e.target.value)}/>
            </div>
            <div className="form-group">
                <input className="form-control" type="password" placeholder="Password" name="password" required="" style={{borderBottomColor: 'rgb(55,140,200)'}} onChange={(e)=>setPassword(e.target.value)}/>
            </div>
            <div className="form-group">
                <button className="btn btn-primary btn-block" style={{background:' #378CC8'}} onClick={handleSubmit}>Login</button>
            </div>
            <a className="text-center forgot" style={{textAlign: 'center',marginBottom: '10px'}} data-target="#modal-1" onClick={openModal}>Forgot Password</a>
            <span className="text-center forgot" href="#" style={{textAlign: 'center'}}><Link to="/register">Click Here to Register</Link></span>
        </form>
        </section>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
        >
           
            <div className="modal-dialog modal-dialog-centered" role="document" style={{margin:"0"}}>
                <div className="modal-content">
                    <div className="modal-header" style={{background: '#378cc8'}}>
                        <img src="assets/img/jedatis_logo_2.png" width="20%"/>
                        <button type="button" className="close" onClick={closeModal}><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="modal-body">
                        <h3 style={{textAlign: 'center',color: 'rgb(80,94,108)'}}>Forgot Password</h3>
                        <br/>
                        <form style={{background: '#d9f3fb',maxWidth: '540'}}>
                            <div className="form-group">
                                <input className="form-control" type="email" name="email" placeholder="Email" style={{borderColor: '#378CC8'}} onChange={(e)=>setLemail(e.target.value)} required=""/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button className="btn" type="button"style={{background: '#D3D3D3'}} onClick={closeModal}>Close</button>
                        <button className="btn" type="button" style={{background: '#378cc8',color:'#ffffff'}} onClick={sendResetMail}>Send</button></div>
                </div>
           
        </div>
      </Modal>
        </div>
    )
}

export default Login
