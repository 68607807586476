/*
 * Context file
 */
import { auth } from 'firebase';
import React, { useContext, useEffect, useState } from 'react'
import app from "../firebase"

const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export const AuthProvider = ({children}) => {

    // currentUser, cardAdded, message, loggedIn, firstUser
    // nric, name, gender, phone, address, birthDate, email, visitPurpose, needMedication, preferredClinic

    const [currentUser,setCurrentUser]=useState();
    const[cardAdded,setCardAdded]=useState(false);
    const[nric,setNric]=useState();
    const[name,setName]=useState('');
    const[gender,setGender]=useState('');
    const[phone,setPhone]=useState('');
    const[address,setAddress]=useState('');
    const[birthDate,setBirthDate]=useState('');
    const[email,setEmail]=useState('');
    const[visitPurpose,setVisitPurpose]=useState('');
    const[needMedication,setNeedMedication]=useState('');
    const[preferredClinic,setPreferredClinic]=useState('');
    const[message,setMessage]=useState('');
    const[loggedIn,setLoggedIn]=useState(false);
    const[firstUser,setFirstUser]=useState(false);
    const[passport,setPassport]=useState('');
    const[nationality,setNationality]=useState('');
    const[device,setDevice]=useState('');

    // Register
    function register(email,password){
        return app.auth().createUserWithEmailAndPassword(email, password);
    }

    // Login
    function login(email,password){
        return app.auth().signInWithEmailAndPassword(email, password);
    }

    // Logout
    function logout(){
        return app.auth().signOut();
    }

    // Reset password
    function resetPassword(email){
        return app.auth().sendPasswordResetEmail(email);
    }

    useEffect(()=>{
        const unsubscribe = app.auth().onAuthStateChanged(user=>{
            setCurrentUser(user)
        })
        return unsubscribe
    },[])

    // set nric  
    const nricFinalize=(val)=>{
        setNric(val);
    }


    const value = {
        currentUser,setCurrentUser,
        register,
        login,
        logout,
        resetPassword,
        nric,setNric,
        name,setName,gender,setGender,phone,setPhone,address,setAddress,birthDate,setBirthDate,
        email,setEmail,nricFinalize,
        visitPurpose,setVisitPurpose,needMedication,setNeedMedication,preferredClinic,setPreferredClinic,
        message,setMessage,loggedIn,setLoggedIn,firstUser,setFirstUser,cardAdded,setCardAdded,
        passport,setPassport,nationality,setNationality,device,setDevice
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

