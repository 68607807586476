/*
 * Terms & conditions component - to display terms& conditions
 */

import React, { useState } from 'react'
import '../assets/css/style.css'
import Navbar from '../components/Navbar'

const TermsCondition = () => {

    return (
        <>
        <Navbar/>
        <div class="d-flex d-md-flex justify-content-center align-items-center justify-content-md-center align-items-md-center" style={{color: "var(--indigo)",background: "rgb(255,255,255)"}}>
        <section className="login-clean" style={{background: 'rgb(255,255,255)'}}>
            <form style={{background: '#d9f3fb',maxWidth: '1000px',width:'100%'}}>
                <h4>Welcome to Jedatis Telemedicine Services,</h4>
                <br/>
                <h4>TERMS AND CONDITIONS</h4>
                <br/>
                <ol style={{fontWeight:'600', fontSize:'20px'}}>
                    <li style={{margin:"10px 0px"}}>
                        Teleconsulation is not suitable for emergency consults (e.g. chest pain , breathing difficulties , recurrent vomiting , severe abdominal pain).
                        If you require urgent medical attention , please call 995 or visit our nearest clinic or A&E.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                        This service is not for mental health conditions , please call 1800 221 4444 if you need mental health support.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            Teleconsultaion is solely based on the information provided by me and in the absence of physical evaluation the diagnosis received is limited and provisional.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            I am located in Singapore as specified in my JEDATIS TELEMED Profile.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            Minors should be seen with their parent/guardian.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            The average waiting time to see a doctor is about 15-mins.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            All our healthcare professionals are fully registered with The Singapore Medical Council.
                    </li>
                    <li style={{margin:"10px 0px"}}>
                            The Average Bill Size is $45.
                    </li>
                </ol>
            </form>
        </section>
        </div>
        </>
    )
}

export default TermsCondition

